import { firestore } from 'core/config/firebase';
import { FirestoreCollectionIDs } from 'core/constants/firestore-collection-ids';
import { collection, doc, orderBy, query, QueryConstraint, where } from 'firebase/firestore';
import ApiService from '../api.service';
import {
  IAddCustomerNoteDto,
  IAddCustomerRequestDto,
  ICustomer,
  ICustomerQueryOptions,
  IExportCustomerDataRequestDto,
  ISendMissedCallReminderRequestDto,
  ISendSurveyEmailRequestDto,
  IUpdateCustomerNoteDto,
  IUpdateCustomerRequestDto,
} from './customers-api-interface';
import algoliasearch from 'algoliasearch';
import { FirebaseFunctionNames } from 'core/constants/firebase-function-names';
import { StringIndexable } from 'core/utilities/interface-helpers';
import { CustomerStatus } from 'core/constants/customer-status';
import AuditLogApiService from '../audit-log/audit-log-api.service';
import { IAuditLogChange, IAuditLogPerson } from '../audit-log/audit-log-api-interface';

const client = algoliasearch(process.env.REACT_APP_ALGOLIA_APP_ID!, process.env.REACT_APP_ALGOLIA_API_KEY!);
const index = client.initIndex(process.env.REACT_APP_ALGOLIA_CUSTOMER_INDEX!);

const set = async (dto: IAddCustomerRequestDto, changes: IAuditLogChange[]) => {
  const docRef = doc(firestore, FirestoreCollectionIDs.CUSTOMERS, dto.uid);
  await ApiService.set(docRef, dto);
  return AuditLogApiService.set({
    resourceUid: dto.uid,
    resourceType: 'customer',
    actionType: 'create',
    author: dto.createdBy,
    customerUid: dto.uid,
    changes,
  });
};

const subscribeToLatestCustomers = (subscriptionHandler: Function, errorHandler: Function) => {
  const collectionRef = collection(firestore, FirestoreCollectionIDs.CUSTOMERS);
  const q = query(
    collectionRef,
    where('status', 'in', [
      CustomerStatus.AWAITING_TEST_BOOKING,
      CustomerStatus.TEST_CANCELLED_REARRANGE,
      CustomerStatus.AWAITING_FITTING_BOOKING,
      CustomerStatus.AWAITING_FITTING_BOOKING_SPECIAL,
      CustomerStatus.AWAITING_FITTING_BOOKING_WARRANTY,
      CustomerStatus.FITTING_CANCELLED_REARRANGE,
      CustomerStatus.AWAITING_SERVICE_BOOKING,
      CustomerStatus.URGENT_AWAITING_SERVICE_BOOKING,
      CustomerStatus.AWAITING_TELE_HEAR_BOOKING,
      CustomerStatus.SERVICE_CANCELLED_REARRANGE,
      CustomerStatus.AWAITING_IMPRESSIONS_BOOKING,
      CustomerStatus.IMPRESSIONS_CANCELLED_REARRANGE,
      CustomerStatus.TESTED_NOT_PRESCRIBED,
      CustomerStatus.TEST_CANCELLED,
    ])
  );
  return ApiService.subscribeToCollection(q, subscriptionHandler, errorHandler);
};

const get = async (uid: string) => {
  const docRef = doc(firestore, FirestoreCollectionIDs.CUSTOMERS, uid);
  return ApiService.getData<ICustomer>(docRef);
};

const subscribeToCustomer = (uid: string, subscriptionHandler: Function, errorHandler: Function) => {
  const docRef = doc(firestore, FirestoreCollectionIDs.CUSTOMERS, uid);
  return ApiService.subscribeToDoc<ICustomer>(docRef, subscriptionHandler, errorHandler);
};

const customersQueryList: StringIndexable = {
  statusUpdatedAfter: { property: 'statusUpdatedAt', operator: '>' },
  statusUpdatedBefore: { property: 'statusUpdatedAt', operator: '<' },
  status: { property: 'status', operator: '==' },
  afterDate: { property: 'createdAt', operator: '>' },
  beforeDate: { property: 'createdAt', operator: '<' },
};

const subscribeToCustomers = (
  subscriptionHandler: Function,
  errorHandler: Function,
  queryOptions?: ICustomerQueryOptions,
  ordering?: string[]
) => {
  const collectionRef = collection(firestore, FirestoreCollectionIDs.CUSTOMERS);
  let queryList: QueryConstraint[] = [];
  if (queryOptions) {
    queryList = Object.keys(queryOptions).map((queryKey: string) => {
      const { property, operator } = customersQueryList[queryKey];
      return where(property, operator, queryOptions[queryKey]);
    });
  }

  if (ordering) {
    ordering.forEach((order) => queryList.push(orderBy(order, 'desc')));
  }
  const q = query(collectionRef, ...queryList);
  return ApiService.subscribeToCollection(q, subscriptionHandler, errorHandler);
};

const subscribeToCustomerNotes = (uid: string, subscriptionHandler: Function, errorHandler: Function) => {
  const collectionRef = collection(firestore, FirestoreCollectionIDs.CUSTOMERS, uid, FirestoreCollectionIDs.NOTES);
  const q = query(collectionRef, orderBy('createdAt', 'desc'));
  return ApiService.subscribeToCollection(q, subscriptionHandler, errorHandler);
};

const setCustomerNote = async (customerNoteDto: IAddCustomerNoteDto) => {
  const docRef = doc(
    firestore,
    FirestoreCollectionIDs.CUSTOMERS,
    customerNoteDto.customerUid,
    FirestoreCollectionIDs.NOTES,
    customerNoteDto.uid
  );
  return ApiService.set(docRef, customerNoteDto);
};

const searchForCustomer = async (searchTerm: string) => {
  return await index.search(searchTerm);
};

const update = async (
  uid: string,
  updatedBy: IAuditLogPerson,
  dto: IUpdateCustomerRequestDto,
  changes: IAuditLogChange[]
) => {
  const docRef = doc(firestore, FirestoreCollectionIDs.CUSTOMERS, uid);
  await ApiService.update(docRef, dto);
  return AuditLogApiService.set({
    resourceUid: uid,
    resourceType: 'customer',
    actionType: 'update',
    author: updatedBy,
    customerUid: uid,
    changes,
  });
};

const removeCustomers = async (uids: string[]) => {
  const docRefs = uids.map((uid) => doc(firestore, FirestoreCollectionIDs.CUSTOMERS, uid));
  return ApiService.batchRemove(docRefs);
};

const sendMissedCallEmail = async (dto: ISendMissedCallReminderRequestDto) => {
  const response = await ApiService.callFunction(dto, FirebaseFunctionNames.SEND_CUSTOMER_MISSED_CALL_EMAIL);
  return response;
};

const sendSurveyEmail = async (dto: ISendSurveyEmailRequestDto) => {
  const response = await ApiService.callFunction(dto, FirebaseFunctionNames.SEND_CUSTOMER_SURVEY_EMAIL);
  return response;
};

const sendMissedCallSMS = async (dto: ISendMissedCallReminderRequestDto) => {
  const response = await ApiService.callFunction(dto, FirebaseFunctionNames.SEND_CUSTOMER_MISSED_CALL_SMS);
  return response;
};

const exportCustomerData = async (dto: IExportCustomerDataRequestDto) => {
  const response = await ApiService.callFunction<string>(dto, FirebaseFunctionNames.EXPORT_CUSTOMER_DATA);
  return response;
};

const deleteCustomerNote = async (customerUid: string, noteUid: string) => {
  const docRef = doc(firestore, FirestoreCollectionIDs.CUSTOMERS, customerUid, FirestoreCollectionIDs.NOTES, noteUid);
  return ApiService.remove(docRef);
};

const updateCustomerNote = async (updateDto: IUpdateCustomerNoteDto) => {
  const docRef = doc(
    firestore,
    FirestoreCollectionIDs.CUSTOMERS,
    updateDto.customerUid,
    FirestoreCollectionIDs.NOTES,
    updateDto.uid
  );
  return ApiService.update(docRef, updateDto);
};

const CustomersApiService = {
  set,
  subscribeToLatestCustomers,
  get,
  subscribeToCustomer,
  subscribeToCustomerNotes,
  setCustomerNote,
  searchForCustomer,
  update,
  removeCustomers,
  sendMissedCallEmail,
  sendSurveyEmail,
  subscribeToCustomers,
  sendMissedCallSMS,
  exportCustomerData,
  deleteCustomerNote,
  updateCustomerNote,
};

export default CustomersApiService;
